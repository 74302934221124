import React from "react";
import { PageWrapper } from "~components/Core";
import Gdpr from "~sections/utility/TermsCondition/Gdpr";
import FooterSection from "~sections/FooterFour";
const header = {
  headerClasses: "site-header site-header--menu-start light-header",
  containerFluid: false,
};

export default function TermsAndConditions() {
  return (
    <PageWrapper innerPage={true}>
      <Gdpr />
      <FooterSection />
    </PageWrapper>
  );
}
