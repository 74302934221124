import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Terms from "./style";
export default function Gdpr() {
  return (
    <Terms>
      <Container>
        <Row className="justify-content-center">
          <Col className="col-xl-7 col-lg-8 col-md-11 text-center">
            <Terms.Box>
              <Terms.Title as="h2">Datenschutz</Terms.Title>
            </Terms.Box>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="col-xxl-8 col-xl-9 col-lg-10">
            <Terms.Content>
              <Terms.TitleSmall as="h5">Warum myFeedoo?</Terms.TitleSmall>
              <Terms.Text>
                Wir wollen dabei helfen, bessere Orte zu schaffen. MyFeedoo
                sammelt ehrliches und nützliches Feedback, um Betreibern und
                Kunden zu helfen.
              </Terms.Text>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">
                  Was passiert mit den Daten?
                </Terms.TitleSmall>
                <Terms.Text>
                  Um an einer Befragung teilzunehmen, benötigen wir keine
                  E-Mail-Adresse oder andere Kontaktdaten. Dein Feedback wird
                  dem Gastronomen anonym zugesendet. Wir speichern neben
                  Feedbacksdaten, die freiwillige E-Mail-Adresse Angabe zum
                  Zweck der Kommunikation mit dem Inhaber des Lokals. Die
                  E-Mail-Adresse bleibt anonym für die Inhabern und sind nicht
                  weitergegeben.
                </Terms.Text>
              </Terms.ContentBox>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">
                  Allgemeiner Hinweis und Pflichtinformationen. Benennung der
                  verantwortlichen Stelle:
                </Terms.TitleSmall>
                <Terms.Text>
                  feedoo <br />
                  Damien Servais <br />
                  Lehmbruckstr. 15 <br />
                  10245 Berlin
                </Terms.Text>
                <Terms.List>
                  <Terms.ListItem>
                    Die verantwortliche Stelle entscheidet allein oder gemeinsam
                    mit anderen über die Zwecke und Mittel der Verarbeitung von
                    personenbezogenen Daten z.B. Namen, Kontaktdaten o. Ä..
                  </Terms.ListItem>
                </Terms.List>
              </Terms.ContentBox>
              <Terms.ContentBox>
                <Terms.TitleSmall as="h5">
                  Widerruf Ihrer Einwilligung zur Datenverarbeitung
                </Terms.TitleSmall>
                <Terms.Text>
                  Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge
                  der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits
                  erteilten Einwilligung ist jederzeit möglich. Für den Widerruf
                  genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit
                  der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom
                  Widerruf unberührt.
                </Terms.Text>
                <Terms.TitleSmall as="h5">
                  Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde
                </Terms.TitleSmall>
                <Terms.Text>
                  Als Betroffener steht Ihnen im Falle eines
                  datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der
                  zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde
                  bezüglich datenschutzrechtlicher Fragen ist der
                  Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der
                  Sitz unseres Unternehmens befindet. Der folgende Link stellt
                  eine Liste der Datenschutzbeauftragten sowie deren
                  Kontaktdaten bereit:
                  <a
                    href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
                  </a>
                  .
                </Terms.Text>
                <Terms.TitleSmall as="h5">
                  Recht auf Datenübertragbarkeit
                </Terms.TitleSmall>
                <Terms.Text>
                  Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer
                  Einwilligung oder in Erfüllung eines Vertrags automatisiert
                  verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die
                  Bereitstellung erfolgt in einem maschinenlesbaren Format.
                  Sofern Sie die direkte Übertragung der Daten an einen anderen
                  Verantwortlichen verlangen, erfolgt dies nur, soweit es
                  technisch machbar ist.
                </Terms.Text>
                <Terms.TitleSmall as="h5">
                  Recht auf Auskunft, Berichtigung, Sperrung, Löschung
                </Terms.TitleSmall>
                <Terms.Text>
                  Sie haben jederzeit im Rahmen der geltenden gesetzlichen
                  Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre
                  gespeicherten personenbezogenen Daten, Herkunft der Daten,
                  deren Empfänger und den Zweck der Datenverarbeitung und ggf.
                  ein Recht auf Berichtigung, Sperrung oder Löschung dieser
                  Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema
                  personenbezogene Daten können Sie sich jederzeit über die im
                  Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.
                </Terms.Text>
                <Terms.TitleSmall as="h5">
                  SSL- bzw. TLS-Verschlüsselung
                </Terms.TitleSmall>
                <Terms.Text>
                  Aus Sicherheitsgründen und zum Schutz der Übertragung
                  vertraulicher Inhalte, die Sie an uns als Seitenbetreiber
                  senden, nutzt unsere Website eine SSL-bzw.
                  TLS-Verschlüsselung. Damit sind Daten, die Sie über diese
                  Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen
                  eine verschlüsselte Verbindung an der „https://“ Adresszeile
                  Ihres Browsers und am Schloss-Symbol in der Browserzeile.
                </Terms.Text>
                <Terms.TitleSmall as="h5">Server-Log-Dateien</Terms.TitleSmall>
                <Terms.Text>
                  In Server-Log-Dateien erhebt und speichert der Provider der
                  Website automatisch Informationen, die Ihr Browser automatisch
                  an uns übermittelt. Dies sind:
                  <ul>
                    <li>Besuchte Seite auf unserer Domain</li>
                    <li>Datum und Uhrzeit der Serveranfrage</li>
                    <li>Browsertyp und Browserversion</li>
                    <li>Verwendetes Betriebssystem</li>
                    <li>Referrer URL</li>
                    <li>Hostname des zugreifenden Rechners</li>
                    <li>IP-Adresse</li>
                  </ul>
                </Terms.Text>
                <Terms.Text>
                  Es findet keine Zusammenführung dieser Daten mit anderen
                  Datenquellen statt. Grundlage der Datenverarbeitung bildet
                  Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur
                  Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen
                  gestattet.
                </Terms.Text>

                <Terms.TitleSmall as="h5">
                  Registrierung auf dieser Website
                </Terms.TitleSmall>

                <Terms.Text>
                  Zur Nutzung bestimmter Funktionen können Sie sich auf unserer
                  Website registrieren. Die übermittelten Daten dienen
                  ausschließlich zum Zwecke der Nutzung des jeweiligen Angebotes
                  oder Dienstes. Bei der Registrierung abgefragte Pflichtangaben
                  sind vollständig anzugeben. Andernfalls werden wir die
                  Registrierung ablehnen.
                </Terms.Text>
                <Terms.Text>
                  Im Falle wichtiger Änderungen, etwa aus technischen Gründen,
                  informieren wir Sie per E-Mail. Die E-Mail wird an die Adresse
                  versendet, die bei der Registrierung angegeben wurde.
                </Terms.Text>
                <Terms.Text>
                  Die Verarbeitung der bei der Registrierung eingegebenen Daten
                  erfolgt auf Grundlage Ihrer Einwilligung Art. 6 Abs. 1 lit. a
                  DSGVO. Ein Widerruf Ihrer bereits erteilten Einwilligung ist
                  jederzeit möglich. Für den Widerruf genügt eine formlose
                  Mitteilung per E-Mail. Die Rechtmäßigkeit der bereits
                  erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
                </Terms.Text>
                <Terms.Text>
                  Wir speichern die bei der Registrierung erfassten Daten
                  während des Zeitraums, den Sie auf unserer Website registriert
                  sind. Ihren Daten werden gelöscht, sollten Sie Ihre
                  Registrierung aufheben. Gesetzliche Aufbewahrungsfristen
                  bleiben unberührt.
                </Terms.Text>

                <Terms.TitleSmall>Kontaktformular</Terms.TitleSmall>

                <Terms.Text>
                  Per Kontaktformular übermittelte Daten werden einschließlich
                  Ihrer Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu
                  können oder um für Anschlussfragen bereitzustehen. Eine
                  Weitergabe dieser Daten findet ohne Ihre Einwilligung nicht
                  statt.
                </Terms.Text>
                <Terms.Text>
                  Die Verarbeitung der in das Kontaktformular eingegebenen Daten
                  erfolgt ausschließlich auf Grundlage Ihrer Einwilligung Art. 6
                  Abs. 1 lit. a DSGVO. Ein Widerruf Ihrer bereits erteilten
                  Einwilligung ist jederzeit möglich. Für den Widerruf genügt
                  eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der
                  bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt
                  vom Widerruf unberührt.
                </Terms.Text>
                <Terms.Text>
                  Über das Kontaktformular übermittelte Daten verbleiben bei
                  uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung
                  zur Speicherung widerrufen oder keine Notwendigkeit der
                  Datenspeicherung mehr besteht. Zwingende gesetzliche
                  Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben
                  unberührt.
                </Terms.Text>

                <Terms.TitleSmall>Fathom</Terms.TitleSmall>

                <Terms.Text>
                  Unsere Website verwendet den Webanalysedienst Fathom.
                </Terms.Text>
                <Terms.Text>
                  Diese Website benutzt den Open Source Webanalysedienst Fathom.
                  Fathom verwendet keine Cookies, speichert keine Daten auf
                  Ihrem Gerät und zeichnet weiterhin keine personenbezogenen
                  Daten auf. Die Analyse der Webseitenbesuche mittels Fathom
                  erfolgt vollständig anonym. Die Nutzung dieses Analyse-Tools
                  erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
                  Websitebetreiber hat ein berechtigtes Interesse an der
                  anonymisierten Analyse des Nutzerverhaltens, um sowohl sein
                  Webangebot als auch seine Werbung zu optimieren.
                </Terms.Text>

                <Terms.TitleSmall>Google Web Fonts</Terms.TitleSmall>

                <Terms.Text>
                  Unsere Website verwendet Web Fonts von Google. Anbieter ist
                  die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA
                  94043, USA.
                </Terms.Text>
                <Terms.Text>
                  Durch den Einsatz dieser Web Fonts wird es möglich Ihnen die
                  von uns gewünschte Darstellung unserer Website zu
                  präsentieren, unabhängig davon welche Schriften Ihnen lokal
                  zur Verfügung stehen. Dies erfolgt über den Abruf der Google
                  Web Fonts von einem Server von Google in den USA und der damit
                  verbundenen Weitergabe Ihre Daten an Google. Dabei handelt es
                  sich um Ihre IP-Adresse und welche Seite Sie bei uns besucht
                  haben. Der Einsatz von Google Web Fonts erfolgt auf Grundlage
                  von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website
                  haben wir ein berechtigtes Interesse an der optimalen
                  Darstellung und Übertragung unseres Webauftritts.
                </Terms.Text>
                <Terms.Text>
                  Das Unternehmen Google ist für das us-europäische
                  Datenschutzübereinkommen "Privacy Shield" zertifiziert. Dieses
                  Datenschutzübereinkommen soll die Einhaltung des in der EU
                  geltenden Datenschutzniveaus gewährleisten.
                </Terms.Text>
                <Terms.Text>
                  Einzelheiten über Google Web Fonts finden Sie unter:
                  <a href="https://www.google.com/fonts#AboutPlace:about">
                    https://www.google.com/fonts#AboutPlace:about
                  </a>
                  und weitere Informationen in den Datenschutzbestimmungen von
                  Google:
                  <a href="https://policies.google.com/privacy/partners?hl=de">
                    https://policies.google.com/privacy/partners?hl=de
                  </a>
                </Terms.Text>
                <Terms.Text>
                  <small>
                    Quelle: Datenschutz-Konfigurator von
                    <a
                      href="http://www.mein-datenschutzbeauftragter.de"
                      target="_blank"
                      rel="noreferrer"
                    >
                      mein-datenschutzbeauftragter.de
                    </a>
                  </small>
                </Terms.Text>
              </Terms.ContentBox>
            </Terms.Content>
          </Col>
        </Row>
      </Container>
    </Terms>
  );
}
